const TYPES = {
  title: 'title',
  label: 'label',
  grid: 'grid',
  message: 'message',
  paper: 'paper',
  table: 'table',
  button: 'button',
  FORM: {
    text: 'form_text',
    select: 'form_select',
    file: 'form_file',
    checkbox: 'form_checkbox'
  }
}

const ACTION_TYPES = {
  navlink: 'navlink',
  download: 'download',
  ajax: 'ajax',
  TABLE: {
    inner: 'inner',
    to: 'to',
    blank: 'blank'
  }
}

module.exports = {
  TYPES,
  ACTION_TYPES
}