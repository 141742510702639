import { Icon, IconButton, Tooltip } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useDispatch } from 'react-redux'

import { ACTION_TYPES } from '../../utils/components'
import { commonActions } from '../../actions/Common'
import { confirmActions } from '../../actions'

const rootStyle = css({
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'flex-end',
  minHeight: 40
})

const tooltipRootStyle = css({
  fontSize: '1rem'
})

const TableActions = (props) => {
  const dispatch = useDispatch()

  const { actions, item } = props

  const { appConfig } = useSelector(state => state.appReducer)

  const getAction = (action) => {
    let actionProps = {}

    switch (action.type) {
      case ACTION_TYPES.TABLE.to:
        actionProps = {
          component: NavLink,
          to: ['', appConfig.appPrefix + action.url, item.id].join('/')
        }

        break
      case ACTION_TYPES.TABLE.inner:
        actionProps = {
          onClick: () => {
            if (action.confirm) {
              dispatch(confirmActions.handleOpen(action.confirm, () => {
                dispatch(commonActions.callPost({}, { id: item.id }, ['', action.url].join('/'), {...action}))
              }))
            } else {
              dispatch(commonActions.callPost({}, { id: item.id }, ['', action.url].join('/'), {...action}))
            }
          }
        }

        break
      case ACTION_TYPES.TABLE.blank:
        let href = action.url

        if (action.id && item._actions && item._actions[action.id]) {
          href = item._actions[action.id]
        }

        actionProps = {
          component: 'a',
          href,
          target: '_blank',
          rel: 'noopener noreferrer',
        }

        break
      default:
        break
    }

    switch (action.type) {
      case ACTION_TYPES.TABLE.inner:
      case ACTION_TYPES.TABLE.to:
      case ACTION_TYPES.TABLE.blank:
        return (
          <div>
            <IconButton
              aria-label={action.tooltip || ''}
              {...actionProps}
            >
              <Icon>{action.icon}</Icon>
            </IconButton>
          </div>
        )
      default:
        return (
          <div></div>
        )
    }
  }

  return (
    <div css={rootStyle}>
      {actions.map((action, actionIndex) => {
        if (action.hideIf && item[action.hideIf]) {
          return null
        }

        if (action.tooltip) {
          return (
            <Tooltip
              title={action.tooltip}
              css={tooltipRootStyle}
              placement='top'
              key={actionIndex}
            >
              {getAction(action)}
            </Tooltip>
          )
        }

        return (
          <div key={actionIndex}>
            {getAction(action)}
          </div>
        )
      })}
    </div>
  )
}

export default TableActions
